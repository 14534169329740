/* tslint:disable:variable-name */
import { delay } from '../../utils/helper'
import { formatSignInError } from './error'
import { replaceHeaderAndFooter, updatePageLoadRankBtnArea } from './fetch'

export function initSigninHooks() {
  $(document).on('ajax:beforeSend', '#sign_up form', async event => {
    $('#modal #sign_up .-invalid').removeClass('-invalid')
    $('#modal #sign_up .error-text').text('')
    $('#modal #sign_up form [type=submit]').prop('disabled', true)
  })

  $(document).on('ajax:complete', '#sign_up form', async event => {
    $('#modal #sign_up form [type=submit]').prop('disabled', false)
  })

  $(document).on('ajax:success', '#sign_up form', async event => {
    const modal = $('#modal')
    const res = event.detail[0]
    const jobId = parseInt(res['job_typ'] as string, 10)
    const site_type = $('#user_site_type').val()
    await delay(150)
    // 看護師・准看護師・看護助手の時は専用pageへ
    // window.location.hash = `modal-sign_up-${[2, 3].includes(jobId) ? 4 : 3}`
    // 一旦キャンペン終了 2020-01-10
    // window.location.hash = 'modal-sign_up-3'
    window.location.href = `/users/${site_type}/before_confirm`
    await delay(150)
    modal['iziModal']('close')
    const r = await replaceHeaderAndFooter()
    const pathName = window.location.pathname
    const matchData = pathName.match(/\/hospital_infos\/(.+)\/rank/)
    if (matchData) {
      await updatePageLoadRankBtnArea(matchData[1])
    }
  })

  $(document).on('ajax:error', '#sign_up form', event => {
    const res = event.detail[0]
    const hashTo = formatSignInError(res)
    // reCAPTCHAもリセット
    // window['grecaptcha'].reset()
    if (hashTo) {
      window.location.hash = hashTo
    }
  })
}
