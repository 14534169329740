/* tslint:disable:variable-name */
import { delay, togglePointBallons, toggleReviewFieldBtn } from '../../utils/helper'
import { formatSummaryError } from './error'
import { addReviewForm, likeReview, updateSearchResults, updateSummaryForm, updateViewPoint } from './fetch'

export function initSummaryHooks() {

  let $site_type = $("input[name='site_type']").val()
  $(document).on('ajax:beforeSend', '#summary_form form', async event => {
    $('#modal #summary_form .-invalid').removeClass('-invalid')
    $('#modal #summary_form .error-text').text('')
    $('#modal #summary_form form [type=submit]').prop('disabled', true)
    $('#modal #summary_form form .addReviewAreaBtn').prop('disabled', true)
  })

  // この辺りのボタンは別のどころで制御している
  // $(document).on('ajax:complete', '#summary_form form', async event => {
  //   $('#modal #summary_form form [type=submit]').prop('disabled', false)
  // })

  $(document).on('ajax:success', '#summary_form form', async event => {
    // const res = event.detail[0]
    // console.log(event);
    await Promise.all([
      updateSearchResults(window['HOSP_ID']),
      delay(150),
    ])
    window.location.hash = 'modal-add_summary-6'
    await delay(150)
    const r = await updateViewPoint()
  })

  $(document).on('ajax:error', '#summary_form form', async event => {
    // console.log(event);
    // const res = event.detail[0]
    await delay(150)
    const $errors = $('#add_summary_error_msg')
    if ($errors.val()) {
      const err = JSON.parse($errors.val().toString())
      const hasTo = formatSummaryError(err)
      if (hasTo) {
        window.location.hash = hasTo
      }
    }
  })

  $(document).on('click', '.-newSummaryBtn', async ev => {
    const $el = $(ev.currentTarget)
    const hosp_id = $el.data('hosp_id')
    // const type = $el.val()

    try {
      $el.prop('disabled', true)
      $el.siblings().prop('disabled', true)
      await updateSummaryForm(hosp_id)
      window.location.hash = 'modal-add_summary-2'
      // const type = $('#add_summary_area #summary_type').val()
      // updateSummaryModal(type.toString()) // 多分いらない...
    } catch (error) {
      window.location.hash = 'modal-add_summary-1'
    } finally {
      $el.prop('disabled', false)
      $el.siblings().prop('disabled', false)
    }
  })

  $(document).on('click', '.addReviewAreaBtn', async ev => {
    const $el = $(ev.currentTarget)
    const hosp_id = $('#new_summary_modal [name="review_summary[hospital_id]"]').val()
    const $area = $el.closest('[id^=add_summary-]')
    const $btns = $area.find('.addReviewAreaBtn, .submitReviewBtn')
    const themeIds = []
    $area.find('.-reviewTheme').each((i, el) => {
      const themeId = $(el).val()
      if (themeId) {
        themeIds.push(themeId)
      }
    })

    try {
      $btns.prop('disabled', true)
      await addReviewForm(hosp_id, themeIds)
      togglePointBallons($area)
    } catch (error) {
      window.location.hash = 'modal-add_summary-4'
    } finally {
      toggleReviewFieldBtn($area)
    }
  })

  $(document).on('click', '.YesNo__btn.-like, .YesNo__btn.-dislike, .YesNo__btn.-undo', async ev => {
    const $el = $(ev.currentTarget)
    const $btnSet = $el.closest('.ReviewsItem__yesNo')
    const reviewId = $btnSet.data('review_id')
    const likeType = $el.data('like_type')
    try {
      $btnSet.find('.YesNo__btn').prop('disabled', true)
      await likeReview(reviewId, likeType)
    } catch (e) {
      console.error(e)
    } finally {
      await delay(300)
      $btnSet.find('.YesNo__btn').prop('disabled', false)
    }
  })
}
