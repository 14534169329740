/* tslint:disable:variable-name */
import { delay, updateSummaryModal } from '../../utils/helper'
// import { formatSignInError } from './error'
import { followHosp, unlockHosp, unlockRank, updateViewPoint } from './fetch'
import { initSigninHooks } from './sign_in'
import { initSummaryHooks } from './summary'

// $(document).on('turbolinks:load', () => {
// $(document).on('click', '#modal [type=submit]', async event => {
//   $('.ModalWrap .-invalid').removeClass('-invalid')
// })

initSigninHooks()
initSummaryHooks()

$(document).on('submit', 'form[data-form-type="search_form"]', event => {
  const $el = $(event.currentTarget)
  const searchValue = $el.find('[name="keyword"]').val()
  if (!searchValue) {
    // console.log($el.find('[type="submit"]'))
    // return false
    setTimeout(() => {
      $.rails.enableElement($el.find('[type="submit"]').get(0))
    }, 200)
    return false
  } else {
    return true
  }
})

$('.-beforeLoginBtn').on('click', async ev => {
  alert('こちらの機能はログインユーザー向けの機能です。\nログインしてご利用ください。')
  return false
})

$(document).on('click', '.-followHosp, .-unfollowHosp', async ev => {
  const $site_type = $("input[name='site_type']").val()
  const $el = $(ev.currentTarget)
  const hosp_id = window['HOSP_ID']
  const type = $el.hasClass('-unfollowHosp') ? 'unfollow' : 'follow'
  $(this).addClass("Btn9")
  try {
    $('.-followHosp, .-unfollowHosp').prop('disabled', true)
    await followHosp(hosp_id, type)
  } catch (error) {
    if (error.responseJSON) {
      const errorText = error.responseJSON.error
      alert(errorText)
    }
  } finally {
    $('.-followHosp, .-unfollowHosp').prop('disabled', false)
  }
})

$(document).on('click', '.-unfollowHospList', async ev => {
  const $site_type = $("input[name='site_type']").val()
  const $el = $(ev.currentTarget)
  const hosp_id = $el.data('hosp_id')
  const hosp_name = $el.data('hosp_name')
  const type = 'unfollow'
  const exitFlg = confirm(`「${hosp_name}」のフォローをやめますか？`)
  if (!exitFlg) {
    return false
  }
  try {
    $el.prop('disabled', true)
    await followHosp(hosp_id, type)
    alert(`「${hosp_name}」のフォローを解除しました。`)
    location.reload()
  } catch (error) {
    if (error.responseJSON) {
      const errorText = error.responseJSON.error
      alert(errorText)
    }
  } finally {
    $el.prop('disabled', false)
  }
})

$(document).on('click', '.-unlockHospBtn', async ev => {
  const $el = $(ev.currentTarget)
  const hosp_id = $el.data('hosp_id')

  try {
    $el.prop('disabled', true)
    await unlockHosp(hosp_id, location.pathname)
    await updateViewPoint()
    // リロード前のクッションモーダル
    window.location.hash = 'modal-unlock_hosp-2'
    // 5秒経ったら自動でモーダルを解除
    await delay(5000)
    const modal = $('#modal')
    modal['iziModal']('close')
  } catch (error) {
    let errorType = ''
    if (error.responseJSON) {
      errorType = error.responseJSON.error
    }
    let hashTo = 'modal-unlock_hosp-3'
    if (errorType === 'has_permission') {
      hashTo = 'modal-unlock_hosp-4'
    }
    window.location.hash = hashTo
  } finally {
    $el.prop('disabled', false)
  }
})

$(document).on('click', '.-loadRankHosps', async ev => {
  const $el = $(ev.currentTarget)
  let pref = $el.data('pref')
  if (!pref) {
    const pathName = window.location.pathname
    const matchData = pathName.match(/\/hospital_infos\/(.+)\/rank/)
    if (matchData) {
      pref = matchData[1]
    }
  }
  try {
    $el.prop('disabled', true)
    await unlockRank(pref)
    await updateViewPoint()
    $('#pageLoadRankBtn').hide()

    const modal = $('#modal')
    modal['iziModal']('close')
  } catch (error) {
    window.location.hash = 'modal-unlock_rank-2'
  } finally {
    $el.prop('disabled', false)
  }
})

// })
