/* tslint:disable:variable-name */
const columns = {
  sign_up: {
    email: 'メールアドレス',
    password: 'パスワード',
    agreement: '利用規約',
    nickname: 'ニックネーム',
    prefecture_id: '都道府県',
    job_typ: '職種',
    job: '職種',
  },
  summary: {
    'annual_income': '年収',
    'evaluation': '評価',
    'time': '勤務時期',
    'paid_leave_rate': '有給消化率',
    'hospital_id': '事務所',
    'reviews.review': '口コミ',
    'reviews.theme': 'カテゴリー',
  },
}

export function formatSignInError(errorJson) {
  const { errors } = errorJson
  const keys = Object.keys(errors)
  let hashTo = ''
  for (const key of keys) {
    const $input = $(`#sign_up [name="user[${key}]"]`)
    switch (key) {
      case 'email':
      case 'password':
        $input.addClass('-invalid')
        $input.next('.error-text').text(columns.sign_up[key] + errors[key][0])
        hashTo = 'modal-sign_up-1'
        break
      case 'agreement':
        $input.parent('label').addClass('-invalid')
        hashTo = 'modal-sign_up-1'
        break
      case 'nickname':
        $input.next('.error-text').text(columns.sign_up[key] + errors[key][0])
      case 'prefecture_id':
      case 'job_typ':
        $input.addClass('-invalid')
        $input.next('.error-text').text(columns.sign_up[key] + errors[key][0])
        // hashTo = 'modal-sign_up-2'
        break
      case 'job':
        const $jobInput = $('#sign_up [name="user[job_typ]"]')
        $jobInput.addClass('-invalid')
        $jobInput.next('.error-text').text(columns.sign_up[key] + errors[key][0])
        break
      // case 'recaptcha':
      //   $('#sign_up .recaptcha-error-text.error-text').text(errors[key][0])
      default:
        break
    }
  }
  return hashTo
}

export function formatSummaryError(errors) {
  const keys = Object.keys(errors)
  const cols = columns.summary
  let hashToId: number = 99
  for (const key of keys) {
    let inputQuery = `#modal #summary_form [name="review_summary[${key}]"]`
    let $input = $(inputQuery)
    switch (key) {
      case 'hospital_id':
        const name = 'hospital_name_or_hospital_name_kana_or_short_name_cont'
        inputQuery = `#modal #search_hosp_modal [name="q[${name}]"]`
        $input = $(inputQuery)
        $input.addClass('-invalid')
        $input.next('.error-text').text(cols[key] + errors[key][0])
        // hashTo = 'modal-add_summary-1'
        if (hashToId > 1) {
          hashToId = 1
        }
        break
      case 'time':
      case 'paid_leave_rate':
        $input.addClass('-invalid')
        $input.next('.error-text').text(cols[key] + errors[key][0])
        // hashTo = 'modal-add_summary-2'
        if (hashToId > 2) {
          hashToId = 2
        }
        break
      case 'evaluation':
        $('#eval_error_msg').text(cols[key] + 'を入れてください')
        // hashTo = 'modal-add_summary-3'
        if (hashToId > 3) {
          hashToId = 3
        }
        break
      case 'reviews.review':
      case 'reviews.theme':
        const $field = $('#modal #review_fields .review_field')
        const textMin = parseInt(window['REVIEW_TEXT_MIN'], 10)
        const textMax = parseInt(window['REVIEW_TEXT_MAX'], 10)
        $field.each((i, el) => {
          const $textArea = $(el).find('.-reviewText')
          const $textAreaErrorText = $textArea.next('.error-text')
          const textSize = $textArea.val().toString().replace(/\s|　/g, '').length

          const $select = $(el).find('.-reviewTheme')
          const $selectErrorText = $select.next('.error-text')

          if (!$select.val()) {
            $select.addClass('-invalid')
            $selectErrorText.text('カテゴリーを選択してください')
          }

          if (textSize < textMin) {
            $textAreaErrorText.text(`${textMin}文字以上入力してください`)
            $textArea.addClass('-invalid')
          }
          if (textSize > textMax) {
            $textAreaErrorText.text(`最大${textMax}文字入力できます`)
            $textArea.addClass('-invalid')
          }
        })
        // hashTo = 'modal-add_summary-4'
        if (hashToId > 4) {
          hashToId = 4
        }
        break
      case 'annual_income':
        $input.addClass('-invalid')
        $input.next('.error-text').text(cols[key] + errors[key][0])
        // hashTo = 'modal-add_summary-5'
        if (hashToId > 5 && hashToId !== 4) {
          hashToId = 5
        }
        break
      default:
        break
    }
  }
  if (hashToId === 99) {
    hashToId = 2
  }
  const hashTo = `modal-add_summary-${hashToId}`
  return hashTo
}
