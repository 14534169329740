/* tslint:disable:variable-name */

export function replaceHeaderAndFooter() {
  const headerUrl = '/api/header.js?replace=1'
  const footerUrl = '/api/footer.js?replace=1'
  return Promise.all([
    new Promise((resolve, reject) => {
      $.ajax(headerUrl).done(resolve).fail(reject)
    }),
    new Promise((resolve, reject) => {
      $.ajax(footerUrl).done(resolve).fail(reject)
    }),
    updateModalAreaAfterSignIn(),
    updateCvArea(),
    updateReviewArea(),
  ])
}

export function unlockHosp(hosp_id: number | string, path: string) {
  const url = `/api/${hosp_id}/unlock_hosp.js?path=${path}`
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}

export function updateViewPoint() {
  const url = '/api/update_view_point?replace=1'
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}

export function updateCvArea() {
  const url = '/api/update_cv_area.js?replace=1'
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}

export function updateModalAreaAfterSignIn() {
  const hosp_id = window['HOSP_ID']
  let url = '/api/update_modal_area.js?replace=1&after_sign_in=1'
  if (hosp_id) {
    url = `/api/update_modal_area.js?hosp_id=${hosp_id}&replace=1&after_sign_in=1`
  }
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}

export function followHosp(hosp_id, type?: string, replace = 1) {
  if (!hosp_id) { return }
  const params = $.param({ replace, type})
  const url = `/api/${hosp_id}/follow_hosp.js?${params}`
  
  return new Promise((resolve, reject) => {
    var f = $.ajax(url).done(resolve).fail(reject)
  })
}

export function updateReviewArea() {
  const hosp_id = window['HOSP_ID']
  const path = location.pathname
  const url = `/api/${hosp_id}/update_review_area.js?path=${path}`
  if (!hosp_id) {
    return
  }
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}

export function updateSummaryForm(hosp_id) {
  if (!hosp_id) { return }
  const url = `/api/${hosp_id}/new_summary.js?replace=1`
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}

export function addReviewForm(hosp_id, themeIds: number[]) {
  if (!hosp_id) { return }
  const params = $.param({ replace: 1, theme_ids: themeIds })
  const url = `/api/${hosp_id}/new_review_form.js?${params}`
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}

export function updateSearchResults(hosp_id) {
  if (!hosp_id) { return }
  // const url = `/api/${hosp_id}/new_summary.js?replace=1`
  const url = `/api/search_hosp.js?utf8=%E2%9C%93&replace=1&q%5Bhospital_info_id_eq%5D=${hosp_id}`
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}

export function submitRailsUjsForm(form: HTMLElement) {
  const Rails = window['Rails']
  Rails.fire(form, 'submit')
}

export function unlockRank(pref_en) {
  if (!pref_en) { return }
  const params = $.param({ replace: 1 })
  const url = `/api/${pref_en}/unlock_rank.js?${params}`
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}

export function updatePageLoadRankBtnArea(pref_en: string) {
  const params = $.param({ replace: 1, pref: pref_en })
  const url = `/api/update_page_load_rank_btn_area.js?${params}`
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}

export function likeReview(reviewId: number | string, likeType: string) {
  const params = $.param({ replace: 1 })
  const url = `/api/like/${reviewId}/${likeType}.js?${params}`
  return new Promise((resolve, reject) => {
    $.ajax(url).done(resolve).fail(reject)
  })
}
